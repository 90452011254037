var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_c("app-header")]
          },
          proxy: true
        }
      ])
    },
    [_c("div", { staticClass: "container" }, [_c("router-view")], 1)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }